<template>
  <BaseLoading v-if="isLoading" class="fit-height"></BaseLoading>
  <div class="auth-wrapper" v-else>
    <section class="auth-card" v-if="!tokenIsValid">
      <div class="col-1">
        <template>
          <img src="@/assets/images/resetPassword/alert.png" width="160px" />
          <h3 style="color: #ff3750; margin: 15px 0 20px 0">Invalid access token</h3>
          <p class="grey--text">Your can't access to this action. please try again.</p>
          <div class="other-auth">
            <routerLink :to="{ name: 'login' }"> Back to login </routerLink>
          </div>
        </template>
      </div>
      <div class="col-2">
        <img
          class="img-2"
          src="@/assets/images/resetPassword/lock-page-character1.png"
          alt=""
          width="160%"
        />
      </div>
    </section>
    <section class="auth-card" v-else>
      <div class="col-1">
        <template v-if="!newPasswordConfirmed">
          <img
            class="img-1"
            src="@/assets/images/resetPassword/lock.png"
            alt=""
            width="30%"
          />
          <h2>Create new Password</h2>
          <form @submit.prevent="confirmNewPassword">
            <h5 style="text-align: center; margin-bottom: 10px">
              Enter the new password at least 6 characters long.
            </h5>
            <Input
              label="Password"
              v-model="password"
              :validation="['required']"
              @isValid="PasswordIsValid"
              :checkValidationFlag="checkValidationFlag"
              type="password"
              icon="bx bxs-lock bx-tada"
            ></Input>
            <Input
              label="Password Confirm"
              v-model="passwordConfirm"
              :validation="['required']"
              @isValid="PasswordConfirmIsValid"
              :checkValidationFlag="checkValidationFlag"
              type="password"
              icon="bx bxs-lock bx-tada"
            ></Input>
            <p
              :style="passwordsMatch ? 'opacity:0' : 'opacity:1'"
              class="passwords-not-match"
            >
              <i class="bx bxs-error-circle bx-tada"></i> Passwords do Not match. Try
              again
            </p>
            <button type="submit" class="auth-btn login-btn lt-hover">
              <Loading v-if="isLoading"></Loading>
              <div style="display: flex; align-items: center" v-else>
                Create password
                <i class="bx bxs-lock bx-burst"></i>
              </div>
            </button>
          </form>
        </template>

        <template v-else>
          <img
            src="@/assets/images/resetPassword/tick1.png"
            width="160"
            style="aspect-ratio: 3/2"
          />
          <h3 style="text-align: center; color: #3ce325; margin: 15px 0 20px 0">
            Password changed successfully
          </h3>
          <p class="grey--text text-center">
            Your can login with your email and new password confirmed.
          </p>
          <div class="other-auth">
            <routerLink :to="{ name: 'login' }"> Back to login </routerLink>
          </div>
        </template>
      </div>
      <div class="col-2">
        <img
          class="img-2"
          src="@/assets/images/resetPassword/lock-page-character1.png"
          alt=""
          width="160%"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      confirmLoading: false,
      password: null,
      passwordValidation: null,
      passwordConfirm: null,
      passwordConfirmValidation: null,
      passwordsMatch: true,
      token: null,
      tokenIsValid: false,
      newPasswordConfirmed: false,

      checkValidationFlag: false,
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  watch: {
    passwordConfirm() {
      this.passwordsIsMatch();
    },
    password() {
      if (this.passwordConfirm) this.passwordsIsMatch();
    },
  },
  created() {
    this.token = this.$route.query.token;
    this.checkToken();
  },
  methods: {
    ...mapActions(["resetPasswordCheckToken", "resetPasswordConfirmNewPassword"]),
    PasswordIsValid(v) {
      this.passwordValidation = v;
    },
    PasswordConfirmIsValid(v) {
      this.passwordConfirmValidation = v;
    },
    checkToken() {
      if (this.token) {
        this.isLoading = true;
        this.resetPasswordCheckToken({ token: this.token }).then((result) => {
          
          this.tokenIsValid = result.data; //its 0 or 1
          this.isLoading = false;
        });
      }
    },
    confirmNewPassword() {
      if (this.passwordValidation && this.passwordConfirmValidation) {
        this.confirmLoading = true;
        const form = {
          password: this.password,
          token: this.token,
        };
        
        this.resetPasswordConfirmNewPassword(form)
          .then((result) => {
            this.newPasswordConfirmed = true;
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
    passwordsIsMatch() {
      if (this.password === this.passwordConfirm) {
        //check passwords match
        this.passwordsMatch = true;
      } else {
        //if passwords not match
        this.passwordsMatch = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_reg&login.scss";
.auth-wrapper {
  height: 100vh;
  .col-1 {
    padding: 50px 20px !important;
    form {
      margin-top: 15px !important;
    }
    .img-1 {
    }
  }
  .col-2 {
    background-position: left;
  }
}
.fit-height {
  height: 100vh;
}
</style>
